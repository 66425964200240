@import '@entur/typography/dist/styles.css';
@import '@entur/grid/dist/styles.css';
@import '@entur/form/dist/styles.css';
@import '@entur/button/dist/styles.css';
@import '@entur/icons/dist/styles.css';
@import '@entur/layout/dist/styles.css';
@import '@entur/loader/dist/styles.css';
@import '@entur/tooltip/dist/styles.css';
@import '@entur/alert/dist/styles.css';
@import '@entur/expand/dist/styles.css';
@import '@entur/tooltip/dist/styles.css';

body {
  margin: 0;
}