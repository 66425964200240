.resultsContainer {
  padding: 20px;
}

.resultsHeading {
  border-bottom: 1px solid #e9e9e9;
}

.resultContainer {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.result {
}

.resultIcon {
  color: #181c56;
  width: 16px;
  height: 16px;
}

.warningIcon {
  color: red;
  width: 16px;
  height: 16px;
}

.warningContainer {
  width: 16px;
}

.resultDetail {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  overflow-wrap: break-word;
}

.iconsContainer {
  display: flex;
  align-items: center;
  height: 40px
}

.icons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2px;
  width: 34px;
  height: 34px;

  > div {
    height: 16px;
  }

}
